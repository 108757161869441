import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field-auto-complete-multiple" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }

import { PropType, computed, reactive, ref, watch } from 'vue';
import Autocomplete, { Item } from './Autocomplete.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AutocompleteMultiple',
  props: {
  modelValue: {
    required: false,
    type: Array as PropType<any[]>,
    default: () => [],
  },
  items: {
    type: Array as PropType<Item[]>,
    required: true,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const value = reactive<any[]>([...props.modelValue]);
const editableValue = reactive<{ index: number; value: any }>({
  index: -1,
  value: null,
});

const showInput = ref<boolean>(false);

const addItem = () => {
  value.push(-1);
  editValue(-1, value.length - 1);
};
const removeItem = () => {
  value.splice(editableValue.index, 1);

  showInput.value = false;
};
const getLabel = (v: any) =>
  props.items.find((i) => i.value === v)?.label ||
  (showInput.value ? '' : 'choose');
const editValue = (v: any, index: number) => {
  editableValue.index = index;
  editableValue.value = v;
  showInput.value = true;
};
const update = (v: any) => {
  value[editableValue.index] = v.value;

  showInput.value = false;

  emit('update:modelValue', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "fake-input",
      onClick: _withModifiers(addItem, ["prevent"])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (item, index) => {
        return (_openBlock(), _createElementBlock("span", {
          onClick: _withModifiers(($event: any) => (editValue(item, index)), ["stop","prevent"])
        }, [
          _createTextVNode(_toDisplayString(getLabel(item)), 1),
          (index !== value.length - 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, ", "))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      }), 256)),
      (showInput.value)
        ? (_openBlock(), _createBlock(Autocomplete, {
            key: 0,
            onChanged: update,
            modelValue: editableValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editableValue.value) = $event)),
            items: props.items,
            focus: ""
          }, null, 8, ["modelValue", "items"]))
        : _createCommentVNode("", true),
      (showInput.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            onClick: _withModifiers(removeItem, ["stop","prevent"])
          }, " - "))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})