import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
  name: {
    type: String,
  },
},
  setup(__props) {


const img = computed(() => `/img/icons/${__props.name}.png`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    class: "box-icon",
    width: "70",
    src: img.value
  }, null, 8, _hoisted_1))
}
}

})