import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box"
}
const _hoisted_2 = { class: "box-option" }
const _hoisted_3 = { class: "week-menu" }
const _hoisted_4 = { class: "shopping-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

import { computed, markRaw, watch } from 'vue';
import { useStore } from 'vuex';
import {
  IngredientRef,
  Meal,
  MealRef,
  Menu,
  NEW_ITEM_ID,
} from '@/models/menu.model';
import { ModalMutations } from '../common/modal/modal.store';
import MealRefForm from './MealRefForm.vue';
import IngredientRefForm from './IngredientRefForm.vue';
import { MenuActions, MenuMutations } from './menu.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuForm',
  setup(__props) {

const store = useStore();

const editMenu = computed<Menu>(() => store.state.us.editMenu);
const meals = computed<Meal[]>(() => store.state.us.meals);
const ingredients = computed<Meal[]>(() => store.state.us.ingredients);

watch(
  () => editMenu.value,
  (v) => {
    if (v.id === NEW_ITEM_ID) {
      store.dispatch(MenuActions.GetMenu, { year: v.year, week: v.week });
    }
  },
);

const editDay = (meal: MealRef) => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(MealRefForm),
    data: meal,
  });
};

const editIngredient = (index: number, ref: IngredientRef) => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(IngredientRefForm),
    data: { index, ref },
  });
};

const addIngredient = () => {
  store.commit(MenuMutations.UpdateMenuShoppingList, {
    index: editMenu.value.shopping_list.length,
    ingredientRef: { id: -1 },
  });
};

const removeIngredient = (index: number) => {
  store.commit(MenuMutations.RemoveMenuShoppingListItem, index);
};

const getMealName = (ref: MealRef): string => {
  let meal = '';

  for (let index = 0; index < meals.value.length; index++) {
    const m = meals.value[index];

    if (m.id === ref.meal_id) {
      meal = m.name_nl || m.name_en! || m.name_id!;
    }
  }

  if (ref.combination_ids) {
    const other = ref.combination_ids
      .map(
        (v) =>
          meals.value.find((i) => i.id === v)?.name_nl ||
          meals.value.find((i) => i.id === v)?.name_en ||
          meals.value.find((i) => i.id === v)?.name_id,
      )
      .join(', ');
    meal = meal ? `${meal}, ${other.toLowerCase()}` : other;
  }

  if (ref.is_left_overs) {
    meal = `Left overs ${meal.toLowerCase()}`;
    if (ref.meal_id) {
      meal = `${meal} (?)`;
    }
  }

  if (ref.is_out) {
    meal = `Eating out ${meal.toLowerCase()}`;
  }

  if (ref.is_undecided) {
    meal = `We'll see ${meal.toLowerCase()}`;
  }

  return ref.notes ? `${meal} ${ref.notes.toLowerCase()}` : meal || 'unknown';
};

const getIngredientName = (ref: IngredientRef): string => {
  let ingredient = '';

  for (let index = 0; index < ingredients.value.length; index++) {
    const m = ingredients.value[index];

    if (m.id === ref.id) {
      ingredient = m.name_nl;
    }
  }

  if (ref.unit && ref.amount) {
    ingredient = `${ref.amount} ${ref.unit} ${ingredient.toLowerCase()}`;
  } else if (ref.amount) {
    ingredient = `${ref.amount} ${ingredient.toLowerCase()}`;
  }

  return ref.notes ? `${ingredient} (${ref.notes})` : ingredient || 'unknown';
};

const save = async () => {
  await store.dispatch(MenuActions.CreateMenu, editMenu.value);
};

return (_ctx: any,_cache: any) => {
  return (editMenu.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, "Menu week " + _toDisplayString(editMenu.value.week) + " - " + _toDisplayString(editMenu.value.year), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "secondary",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (save()), ["prevent"]))
          }, _toDisplayString(editMenu.value.id === -1 ? 'Save' : 'Update'), 1),
          _createElementVNode("p", null, [
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (addIngredient()), ["prevent"]))
            }, "Add item")
          ])
        ]),
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", null, [
            _cache[9] || (_cache[9] = _createElementVNode("span", null, "Saturday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (editDay(editMenu.value.saturday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.saturday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[10] || (_cache[10] = _createElementVNode("span", null, "Sunday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (editDay(editMenu.value.sunday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.sunday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "Monday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (editDay(editMenu.value.monday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.monday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[12] || (_cache[12] = _createElementVNode("span", null, "Tuesday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (editDay(editMenu.value.tuesday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.tuesday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[13] || (_cache[13] = _createElementVNode("span", null, "Wednesday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (editDay(editMenu.value.wednesday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.wednesday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[14] || (_cache[14] = _createElementVNode("span", null, "Thursday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (editDay(editMenu.value.thursday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.thursday)), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[15] || (_cache[15] = _createElementVNode("span", null, "Friday: ", -1)),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (editDay(editMenu.value.friday)), ["prevent"]))
            }, _toDisplayString(getMealName(editMenu.value.friday)), 1)
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("h3", null, "Shopping", -1)),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(editMenu.value.shopping_list, (ref, index) => {
            return (_openBlock(), _createElementBlock("li", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _withModifiers(($event: any) => (editIngredient(index, ref)), ["prevent"])
              }, _toDisplayString(getIngredientName(ref)), 9, _hoisted_5),
              _createElementVNode("a", {
                href: "#",
                onClick: _withModifiers(($event: any) => (removeIngredient(index)), ["prevent"])
              }, " ⅹ ", 8, _hoisted_6)
            ]))
          }), 256))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})