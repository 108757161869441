import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wide columns" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }

import MenuForm from '@/components/menu/MenuForm.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'WeekMenu',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", null, "Menu", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("p", null, "Edit option", -1)),
      _createVNode(_component_Menu)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(MenuForm)
    ])
  ]))
}
}

})