import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "jigsaw-toolbar-item" }

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JigsawToolbarItem',
  setup(__props) {

const menuOpen = ref<boolean>(false);
const items = ref<[]>([]);

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "#",
      class: "toolbar-item",
      onClick: _withModifiers(toggleMenu, ["prevent"])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})