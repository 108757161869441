import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["textContent"]

import { PropType } from 'vue';
import { Ingredient } from '@/models/recipe.model';
import { InputComposable } from './input.composable';
import RecipeIngredientFields from './RecipeIngredientFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeFormIngredients',
  props: {
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object as PropType<Ingredient[]>,
    default: () => [],
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { val, update } = InputComposable<Ingredient[]>(__props.modelValue, emit);

const addIngredient = () => {
  val.value = [
    ...val.value,
    {
      name: '',
    },
  ];
  update();
};
const removeIngredient = (index: number) => {
  val.value.splice(index, 1);
  update();
};

return (_ctx: any,_cache: any) => {
  return (_unref(val))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", {
          textContent: _toDisplayString(__props.label)
        }, null, 8, _hoisted_2),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "labels" }, [
          _createElementVNode("div", null, "Hoeveelheid"),
          _createElementVNode("div", null, "Naam"),
          _createElementVNode("div", null, "Opmerking")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(val), (ingredient, index) => {
          return (_openBlock(), _createBlock(RecipeIngredientFields, {
            key: `ingredient-${index}`,
            modelValue: _unref(val)[index],
            "onUpdate:modelValue": ($event: any) => ((_unref(val)[index]) = $event),
            index: index,
            onRemove: removeIngredient
          }, null, 8, ["modelValue", "onUpdate:modelValue", "index"]))
        }), 128)),
        _createElementVNode("div", { class: "form-option" }, [
          _createElementVNode("button", {
            type: "button",
            onClick: addIngredient
          }, "Ingredient toevoegen")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})