import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_4 = { class: "field-option" }
const _hoisted_5 = ["onClick"]

import { PropType } from 'vue';
import { Step } from '@/models/recipe.model';
import { InputComposable } from './input.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeFormSteps',
  props: {
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object as PropType<Step[]>,
    default: () => [],
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { val, update } = InputComposable<Step[]>(__props.modelValue, emit);

const addStep = () => {
  val.value = [
    ...val.value,
    {
      contents: '',
    },
  ];
  update();
};
const removeStep = (index: number) => {
  val.value.splice(index, 1);
  update();
};

return (_ctx: any,_cache: any) => {
  return (_unref(val))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", {
          textContent: _toDisplayString(__props.label)
        }, null, 8, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(val), (step, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "form-item"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("label", null, null, -1)),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": ($event: any) => ((_unref(val)[index].contents) = $event),
              rows: "4",
              placeholder: `Stap ${index + 1}`
            }, null, 8, _hoisted_3), [
              [_vModelText, _unref(val)[index].contents]
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                type: "button",
                class: "remove",
                onClick: ($event: any) => (removeStep(index))
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("i", { class: "icon icon-close" }, null, -1)
              ]), 8, _hoisted_5)
            ])
          ]))
        }), 128)),
        _createElementVNode("div", { class: "form-option" }, [
          _createElementVNode("button", {
            type: "button",
            onClick: addStep
          }, "Stap toevoegen")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})