import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notifications"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["onClick"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { Mutations } from '@/models/store.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'Notifications',
  setup(__props) {

const store = useStore();
const notifications = computed(() => store.state.notifications);

const dismiss = (uuid: string) => {
  store.commit(Mutations.DismissNotification, uuid);
};

return (_ctx: any,_cache: any) => {
  return (notifications.value.length)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.value, (notification) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass('notification ' + notification.type),
            key: notification.uuid
          }, [
            _createElementVNode("span", {
              textContent: _toDisplayString(notification.text)
            }, null, 8, _hoisted_2),
            _createElementVNode("button", {
              type: "button",
              class: "icon-only",
              onClick: ($event: any) => (dismiss(notification.uuid))
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "icon icon-close" }, null, -1)
            ]), 8, _hoisted_3)
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})