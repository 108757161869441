import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "column" }

import LoginCook from '@/components/user/LoginCook.vue';
import NewRecipes from '@/components/list/NewRecipes.vue';
import LatestChange from '@/components/list/LatestChange.vue';
import PageTitle from '@/components/common/PageTitle.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageTitle),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(LoginCook)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(NewRecipes)
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createVNode(LatestChange)
      ])
    ])
  ], 64))
}
}

})