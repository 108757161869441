import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box box--tertiary" }
const _hoisted_2 = { class: "form-item" }

import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Mutations } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { vFocus } from '@/components/common/directives/focus.directive';


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  setup(__props) {

const store = useStore();
const searchValue = ref<string>('');

onMounted(() => {
  searchValue.value = store.state.searchValue;
});

const update = () => {
  store.commit(Mutations.SetSearch, searchValue.value);
};

const clear = () => {
  searchValue.value = '';
  update();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(update, ["prevent"])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Zoeken", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Zoek hier op de naam, een ingrediënt of iets dergelijks", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-item" }, [
        _createElementVNode("label", { for: "focus" }, "Zoeken")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          name: "search",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchValue).value = $event)),
          onInput: update,
          placeholder: "(ei, boerenkool, kip)"
        }, null, 544), [
          [_vModelText, searchValue.value],
          [_unref(vFocus), true]
        ]),
        (searchValue.value !== '')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "search__clear-button icon-only",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (clear()))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "icon icon-close" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createVNode(Icon, { name: "mushroom" })
  ]))
}
}

})