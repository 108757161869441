import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "form-item" }
const _hoisted_6 = { class: "form-items" }
const _hoisted_7 = { class: "form-item form-multiple" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "form-item" }
const _hoisted_13 = { class: "form-item" }
const _hoisted_14 = { class: "form-item" }
const _hoisted_15 = ["checked"]
const _hoisted_16 = { class: "form-item" }
const _hoisted_17 = ["checked"]

import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { Ingredient, Meal, NEW_ITEM_ID, baseMeal } from '@/models/menu.model';
import { ModalMutations } from '../common/modal/modal.store';
import BeefIcon from './icons/BeefIcon.vue';
import BreadIcon from './icons/BreadIcon.vue';
import ChickenIcon from './icons/ChickenIcon.vue';
import FishIcon from './icons/FishIcon.vue';
import NoodlesIcon from './icons/NoodlesIcon.vue';
import PastaIcon from './icons/PastaIcon.vue';
import PorkIcon from './icons/PorkIcon.vue';
import PotatoIcon from './icons/PotatoIcon.vue';
import RiceIcon from './icons/RiceIcon.vue';
import VegetarianIcon from './icons/VegetarianIcon.vue';
import WrapIcon from './icons/WrapIcon.vue';
import NameInput from './form/NameInput.vue';
import Autocomplete, { Item } from './form/Autocomplete.vue';
import UrlsInput from './form/UrlsInput.vue';
import { MenuActions } from './menu.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MealForm',
  setup(__props) {

const store = useStore();

const editMeal = computed<Meal>(() => store.state.us.editMeal);
const ingredientItems = computed<Item[]>(() =>
  store.state.us.ingredients.map((ing: Ingredient): Item => {
    return {
      label: ing.name_nl,
      search: `${ing.name_en || ''}, ${ing.name_id || ''}, ${(
        ing.keywords || []
      ).join(',')}`,
      value: ing.id,
    };
  }),
);
const meal = reactive<Meal>({ ...baseMeal, ...editMeal.value });

const action = computed<string>(() =>
  meal.id === NEW_ITEM_ID ? 'Add' : 'Edit',
);

const addIngredient = () => {
  meal.ingredients.push({
    id: -1,
    amount: '',
    unit: '',
    notes: '',
  });
};

const removeIngredinet = (index: number) => {
  meal.ingredients.splice(index, 1);
};

watch(
  () => editMeal,
  (newValue) => {
    Object.assign(meal, {
      ...baseMeal,
      ...newValue.value,
    });
  },
  { deep: true },
);

const cancel = () => {
  store.commit(ModalMutations.CloseModal);
};

const submit = async () => {
  if (meal.id !== NEW_ITEM_ID) {
    await store.dispatch(MenuActions.UpdateMeal, { ...meal, variation_of: 0 });
  } else {
    await store.dispatch(MenuActions.CreateMeal, { ...meal, variation_of: 0 });
  }
  cancel();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "box box--tertiary box-modal",
    onSubmit: _withModifiers(submit, ["prevent"])
  }, [
    _createElementVNode("h2", null, _toDisplayString(action.value) + " a Meal", 1),
    _createVNode(NameInput, {
      modelValue: meal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((meal) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[18] || (_cache[18] = _createElementVNode("label", null, "Variation of", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((meal.variation_of) = $event))
      }, null, 512), [
        [
          _vModelText,
          meal.variation_of,
          void 0,
          { number: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[19] || (_cache[19] = _createElementVNode("label", null, null, -1)),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.type_chicken }]),
            title: "Chicken",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (meal.type_chicken = !meal.type_chicken), ["prevent"]))
          }, [
            _createVNode(ChickenIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.type_beef }]),
            title: "Beef",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (meal.type_beef = !meal.type_beef), ["prevent"]))
          }, [
            _createVNode(BeefIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.type_pork }]),
            title: "Pork",
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (meal.type_pork = !meal.type_pork), ["prevent"]))
          }, [
            _createVNode(PorkIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.type_fish }]),
            title: "Fish",
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (meal.type_fish = !meal.type_fish), ["prevent"]))
          }, [
            _createVNode(FishIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.type_vegetarian }]),
            title: "Vegetarian",
            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (meal.type_vegetarian = !meal.type_vegetarian), ["prevent"]))
          }, [
            _createVNode(VegetarianIcon, { size: 40 })
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_rice }]),
            title: "Rice",
            onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (meal.base_rice = !meal.base_rice), ["prevent"]))
          }, [
            _createVNode(RiceIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_potatoes }]),
            title: "Potato",
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (meal.base_potatoes = !meal.base_potatoes), ["prevent"]))
          }, [
            _createVNode(PotatoIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_noodles }]),
            title: "Noodles",
            onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (meal.base_noodles = !meal.base_noodles), ["prevent"]))
          }, [
            _createVNode(NoodlesIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_pasta }]),
            title: "Pasta",
            onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (meal.base_pasta = !meal.base_pasta), ["prevent"]))
          }, [
            _createVNode(PastaIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_bread }]),
            title: "Bread",
            onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (meal.base_bread = !meal.base_bread), ["prevent"]))
          }, [
            _createVNode(BreadIcon, { size: 40 })
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass(["icon-button", { active: meal.base_wrap }]),
            title: "Bread",
            onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (meal.base_wrap = !meal.base_wrap), ["prevent"]))
          }, [
            _createVNode(WrapIcon, { size: 40 })
          ], 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[20] || (_cache[20] = _createElementVNode("label", null, "Ingredients", -1)),
      _createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meal.ingredients, (ing, index) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                class: "small right",
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((ing.amount) = $event)
              }, null, 8, _hoisted_8), [
                [_vModelText, ing.amount]
              ]),
              _withDirectives(_createElementVNode("input", {
                class: "small",
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((ing.unit) = $event)
              }, null, 8, _hoisted_9), [
                [_vModelText, ing.unit]
              ]),
              _createVNode(Autocomplete, {
                modelValue: ing.id,
                "onUpdate:modelValue": ($event: any) => ((ing.id) = $event),
                items: ingredientItems.value,
                class: "large"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "items"]),
              _withDirectives(_createElementVNode("input", {
                class: "medium",
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((ing.notes) = $event)
              }, null, 8, _hoisted_10), [
                [_vModelText, ing.notes]
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "small",
                onClick: _withModifiers(($event: any) => (removeIngredinet(index)), ["prevent"])
              }, " - ", 8, _hoisted_11)
            ]))
          }), 256))
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (addIngredient()), ["prevent"]))
          }, "Add Ingredient")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[21] || (_cache[21] = _createElementVNode("label", null, "Culture", -1)),
      _withDirectives(_createElementVNode("input", {
        class: "large",
        type: "text",
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((meal.culture) = $event))
      }, null, 512), [
        [_vModelText, meal.culture]
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[22] || (_cache[22] = _createElementVNode("label", null, "Urls", -1)),
      _createVNode(UrlsInput, {
        modelValue: meal.recipe_urls,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((meal.recipe_urls) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[23] || (_cache[23] = _createElementVNode("label", null, "Has leftovers", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        id: "completed",
        name: "completed",
        checked: meal.has_left_overs,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((meal.has_left_overs) = $event))
      }, null, 8, _hoisted_15), [
        [_vModelCheckbox, meal.has_left_overs]
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _cache[24] || (_cache[24] = _createElementVNode("label", null, "Side dish", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        id: "completed",
        name: "completed",
        checked: meal.side_dish,
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((meal.side_dish) = $event))
      }, null, 8, _hoisted_17), [
        [_vModelCheckbox, meal.side_dish]
      ])
    ]),
    _createElementVNode("div", { class: "form-buttons" }, [
      _cache[25] || (_cache[25] = _createElementVNode("label", null, null, -1)),
      _createElementVNode("button", {
        type: "button",
        onClick: cancel
      }, "Cancel"),
      _cache[26] || (_cache[26] = _createElementVNode("button", { type: "submit" }, "Save", -1))
    ])
  ], 32))
}
}

})