import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = {
  key: 0,
  class: "column main-large"
}
const _hoisted_3 = { class: "column" }

import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Recipe } from '@/models/recipe.model';
import { AuthLevel } from '../models/auth.model';
import { MenuGroup } from '@/models/navigation.model';
import { Actions, Mutations } from '@/models/store.model';
import PageMenu from '@/components/common/PageMenu.vue';
import RecipeForm from '@/components/recipe/form/RecipeForm.vue';
import PageTitle from '@/components/common/PageTitle.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditRecipe',
  setup(__props) {

const store = useStore();
const router = useRouter();
const route = useRoute();
const recipe = computed<Recipe>(() => store.state.recipe);

onMounted(() => {
  update();
  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Stoppen met bewerken',
      target: `/recipe/${route.params.slug}`,
      group: MenuGroup.Admin,
      level: AuthLevel.Cook,
    },
    {
      label: 'Terug naar de lijst',
      target: '/list',
      group: MenuGroup.Admin,
      level: AuthLevel.Cook,
    },
  ]);
  store.commit(Mutations.SetEditing, true);
});

onUnmounted(() => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.Admin);
  store.commit(Mutations.SetEditing, false);
});

watch(recipe, () => {
  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Terug naar recept',
      target: `/recipe/${recipe.value.slug}`,
      group: MenuGroup.Admin,
      level: AuthLevel.Cook,
    },
  ]);
});

const update = () => {
  const slug = route.params.slug;
  const id = route.params.id;
  if (!slug && !id) {
    store.dispatch(Actions.NewRecipe);
  }
  if (slug) {
    store.dispatch(Actions.GetRecipeBySlug, slug);
  }
  if (id) {
    store.dispatch(Actions.GetRecipeById, id);
  }
  store.dispatch(Actions.GetCategories);
};

const saveRecipe = async (recipe: Recipe) => {
  const update = await store.dispatch(Actions.SaveRecipe, recipe);

  if (update) {
    router.push(`/recipe/${update.slug}`);
  }
};

const cancel = () => {
  router.push(`/recipe/${route.params.slug}`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageTitle),
    _createElementVNode("main", _hoisted_1, [
      (recipe.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
            _createVNode(RecipeForm, {
              recipe: recipe.value,
              onCancel: cancel,
              onUpdateRecipe: saveRecipe
            }, null, 8, ["recipe"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(PageMenu)
      ])
    ])
  ], 64))
}
}

})