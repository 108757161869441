import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box box--tertiary" }
const _hoisted_2 = { class: "form-item" }

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { User } from '@/models/user.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { vFocus } from '@/components/common/directives/focus.directive';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginCook',
  setup(__props) {

const store = useStore();
const router = useRouter();
const auth = ref<Partial<User>>({ name: '' });
const redirect = store.state.redirect;

const submit = async () => {
  const status = await store.dispatch(Actions.Login, {
    type: 'cook',
    auth: auth.value,
  });

  if (status) {
    router.push(redirect ? redirect : '/list');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(submit, ["prevent"])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Welkom", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "description" }, " Om gebruik te kunnen maken van Mom's Lost Marbles dient u in te loggen. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "focus" }, "Naam", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((auth.value.name) = $event)),
          placeholder: "(naam)"
        }, null, 512), [
          [_vModelText, auth.value.name],
          [_unref(vFocus), true]
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-buttons" }, [
        _createElementVNode("label"),
        _createElementVNode("button", { type: "submit" }, "Inloggen")
      ], -1)),
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "description" }, [
        _createTextVNode(" Deze website gaat over en gebruikt "),
        _createElementVNode("i", null, "cookies"),
        _createTextVNode(". ")
      ], -1))
    ], 32),
    _createVNode(Icon, { name: "bowl2" })
  ]))
}
}

})