import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "columns"
}
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "column main" }
const _hoisted_5 = { class: "column" }

import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Recipe } from '@/models/recipe.model';
import { MenuGroup } from '../models/navigation.model';
import { AuthLevel } from '../models/auth.model';
import { Actions, Mutations } from '@/models/store.model';
import RecipeIngredients from '@/components/recipe/RecipeIngredients.vue';
import RecipeChanges from '@/components/recipe/RecipeChanges.vue';
import RecipeCategory from '@/components/recipe/RecipeCategory.vue';
import RecipeContents from '@/components/recipe/RecipeContents.vue';
import RecipeNotes from '@/components/recipe/RecipeNotes.vue';
import PageMenu from '@/components/common/PageMenu.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Recipe',
  setup(__props) {

const store = useStore();
const route = useRoute();
const recipe = computed<Recipe>(() => store.state.recipe);

const update = async () => {
  const slug = route.params.slug;
  const id = route.params.id as string;
  if (slug) {
    await store.dispatch(Actions.GetRecipeBySlug, slug);
  }
  if (id) {
    await store.dispatch(Actions.GetRecipeById, parseInt(id, 10));
  }
  const siteName = 'Moms Lost Marbles';
  document.title = recipe.value
    ? `${recipe.value.name} - ${siteName}`
    : siteName;
  document.body.scrollIntoView();

  store.commit(Mutations.RemoveMenuGroup, MenuGroup.RecipeEdit);
  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Recept bewerken',
      target: route.params.slug
        ? `/recipe/${recipe.value.slug}/edit`
        : `/recipe/by-id/${recipe.value.id}/edit`,
      group: MenuGroup.RecipeEdit,
      level: AuthLevel.Chef,
    },
  ]);
};
watch(route, update);

onMounted(async () => {
  await update();

  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Terug naar de lijst',
      target: '/list',
      group: MenuGroup.Recipe,
      level: AuthLevel.Cook,
    },
  ]);
});

onUnmounted(() => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.Recipe);
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.RecipeEdit);
});

return (_ctx: any,_cache: any) => {
  return (recipe.value)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(RecipeIngredients, { recipe: recipe.value }, null, 8, ["recipe"])
          ]),
          _createVNode(RecipeChanges, { recipe: recipe.value }, null, 8, ["recipe"])
        ]),
        _createElementVNode("section", _hoisted_4, [
          _createVNode(RecipeContents, { recipe: recipe.value }, null, 8, ["recipe"]),
          _createVNode(RecipeCategory, { recipe: recipe.value }, null, 8, ["recipe"])
        ]),
        _createElementVNode("section", _hoisted_5, [
          _createVNode(PageMenu),
          _createVNode(RecipeNotes, { recipe: recipe.value }, null, 8, ["recipe"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})