import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column main" }
const _hoisted_3 = { class: "column" }

import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { MenuGroup } from '@/models/navigation.model';
import { User } from '@/models/user.model';
import { AuthLevel } from '../models/auth.model';
import { Actions, Mutations } from '@/models/store.model';
import Users from '@/components/admin/Users.vue';
import AddUser from '@/components/admin/AddUser.vue';
import EditUser from '@/components/admin/EditUser.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import PageTitle from '@/components/common/PageTitle.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Admin',
  setup(__props) {

const store = useStore();
const route = useRoute();

const verifyAddUser = () => {
  if (route.params.action === 'add-user') {
    const newUser = new User({
      id: -1,
      name: '',
      email: '',
      last_login_date: '',
      user_level: 0,
    });
    store.commit(Mutations.SetEditUser, newUser);
  }
};

const updateMenu = () => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.Admin);
  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Gebruiker toevoegen',
      target: '/admin/add-user',
      group: MenuGroup.Admin,
      level: AuthLevel.Admin,
    },
    {
      label: 'Terug naar de lijst',
      target: '/list',
      group: MenuGroup.Admin,
      level: AuthLevel.Cook,
    },
  ]);
};

const edit_user = computed(() => store.state.edit_user);
const auth = computed(() => store.state.auth);

onMounted(() => {
  updateMenu();
  store.dispatch(Actions.GetUsers);
  verifyAddUser();
});

watch(auth, updateMenu);
watch(route, verifyAddUser);

onUnmounted(() => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.Admin);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageTitle),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(Users)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(PageMenu),
        (edit_user.value)
          ? (_openBlock(), _createBlock(EditUser, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})