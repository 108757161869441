import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "star-icon"
}
const _hoisted_6 = ["onClick", "textContent"]

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Recipe } from '@/models/recipe.model';
import { Actions } from '@/models/store.model';
import { THREE_MONTHS } from '@/util/info';
import Icon from '@/components/common/Icon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeList',
  setup(__props) {

const store = useStore();
const router = useRouter();
const loading = ref<boolean>(false);

const searchValue = computed(() => store.state.searchValue);
const categoryName = () => store.getters.categoryName();
const filteredRecipes = computed(() => store.getters.filteredRecipes);

const isNew = (recipe: Recipe) => {
  return Date.parse(recipe.creation_date) > Date.now() - THREE_MONTHS;
};

const navigate = (recipe: Recipe, byId: boolean = false) => {
  if (byId) {
    router.push(`/recipe/by-id/${recipe.id}`);
  } else {
    router.push(`/recipe/${recipe.slug}`);
  }
};

onMounted(async () => {
  loading.value = true;
  await store.dispatch(Actions.GetRecipes);
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createTextVNode(_toDisplayString(categoryName() || 'Recepten') + " ", 1),
      (searchValue.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createElementVNode("i", null, "met " + _toDisplayString(searchValue.value), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (loading.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "De recepten worden geladen..."))
      : (_openBlock(), _createElementBlock("ol", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredRecipes.value, (recipe) => {
            return (_openBlock(), _createElementBlock("li", {
              key: recipe.id,
              class: "recipe-list-item"
            }, [
              (isNew(recipe))
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: "link",
                onClick: [
                  _withModifiers(($event: any) => (navigate(recipe, true)), ["shift"]),
                  _withModifiers(($event: any) => (navigate(recipe)), ["exact"])
                ],
                textContent: _toDisplayString(recipe.name)
              }, null, 8, _hoisted_6)
            ]))
          }), 128))
        ])),
    _createVNode(Icon, { name: "ricebowl" })
  ]))
}
}

})