import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "summary"
}
const _hoisted_2 = {
  key: 0,
  class: "servings"
}
const _hoisted_3 = {
  key: 1,
  class: "preparation-time"
}

import { computed } from 'vue';
import { Recipe } from '@/models/recipe.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeInfo',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {


const persons = computed(() => {
  return parseInt(__props.recipe.servings, 10) === 1 ? 'persoon' : 'personen';
});

return (_ctx: any,_cache: any) => {
  return (__props.recipe.servings || __props.recipe.preparation_time)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (__props.recipe.servings)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon icon-restaurant" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(__props.recipe.servings) + " " + _toDisplayString(persons.value), 1)
            ]))
          : _createCommentVNode("", true),
        (__props.recipe.preparation_time)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "icon icon-schedule" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(__props.recipe.preparation_time), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})