import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "form-item" }
const _hoisted_5 = { class: "flex" }

import { useStore } from 'vuex';
import { ModalMutations } from '../common/modal/modal.store';
import { PropType, computed, reactive } from 'vue';
import { Meal, MealRef } from '@/models/menu.model';
import Autosuggest from './form/Autosuggest.vue';
import Autocomplete, { Item } from './form/Autocomplete.vue';
import AutocompleteMultiple from './form/AutocompleteMultiple.vue';
import { format } from 'date-fns';
import OutIcon from './icons/OutIcon.vue';
import LeftoversIcon from './icons/LeftoversIcon.vue';
import UndecidedIcon from './icons/UndecidedIcon.vue';
import { MenuMutations } from './menu.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MealRefForm',
  props: {
  data: {
    type: Object as PropType<MealRef>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const store = useStore();

const mealRef = reactive<MealRef>({ ...props.data });

const mealItems = computed<Item[]>(() =>
  store.state.us.meals.map((ing: Meal): Item => {
    return {
      label: ing.name_nl || ing.name_en! || ing.name_id!,
      search: `${ing.name_en || ''}, ${ing.name_id || ''}, ${(
        ing.keywords || []
      ).join(',')}`,
      value: ing.id,
    };
  }),
);

const cancel = () => {
  store.commit(ModalMutations.CloseModal);
};

const formatDate = (date: string): string => {
  const d = new Date(date);

  return format(d, 'EEEE d MMMM yyyy');
};

const submit = () => {
  const day = format(new Date(mealRef.date), 'EEEE').toLowerCase();
  store.commit(MenuMutations.UpdateMenuDay, { day, mealRef });
  cancel();
};

const notesSuggestions: string[] = [
  'met aardappel en groente',
  'met rijst en groente',
  'met groente',
  'en groente',
  'groente',
  'met worst',
];

return (_ctx: any,_cache: any) => {
  return (props.data)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "box box--tertiary box-modal",
        onSubmit: _withModifiers(submit, ["prevent"])
      }, [
        _createElementVNode("h2", null, _toDisplayString(formatDate(props.data.date)), 1),
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("label", null, "Meal", -1)),
          _createVNode(Autocomplete, {
            modelValue: mealRef.meal_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((mealRef.meal_id) = $event)),
            items: mealItems.value,
            class: "large"
          }, null, 8, ["modelValue", "items"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Combined with", -1)),
          _createVNode(AutocompleteMultiple, {
            modelValue: mealRef.combination_ids,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((mealRef.combination_ids) = $event)),
            items: mealItems.value
          }, null, 8, ["modelValue", "items"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, "Notes", -1)),
          _createVNode(Autosuggest, {
            modelValue: mealRef.notes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((mealRef.notes) = $event)),
            items: notesSuggestions
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("label", null, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: _normalizeClass(["icon-button", { active: mealRef.is_out }]),
              title: "Eating out",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (mealRef.is_out = !mealRef.is_out), ["prevent"]))
            }, [
              _createVNode(OutIcon, { size: 40 })
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["icon-button", { active: mealRef.is_left_overs }]),
              title: "Left overs",
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (mealRef.is_left_overs = !mealRef.is_left_overs), ["prevent"]))
            }, [
              _createVNode(LeftoversIcon, { size: 40 })
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["icon-button", { active: mealRef.is_undecided }]),
              title: "We'll see",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (mealRef.is_undecided = !mealRef.is_undecided), ["prevent"]))
            }, [
              _createVNode(UndecidedIcon, { size: 40 })
            ], 2)
          ])
        ]),
        _createElementVNode("div", { class: "form-buttons" }, [
          _cache[10] || (_cache[10] = _createElementVNode("label", null, null, -1)),
          _createElementVNode("button", {
            type: "button",
            onClick: cancel
          }, "Cancel"),
          _cache[11] || (_cache[11] = _createElementVNode("button", { type: "submit" }, "Save", -1))
        ])
      ], 32))
    : _createCommentVNode("", true)
}
}

})