<script lang="ts" setup></script>
<template>
  <main class="page-wide columns">
    <section class="column">
      <ul>
        <li>Menu</li>
        <li>See this weeks menu</li>
        <li>Add next week</li>
        <li>Search</li>
      </ul>
    </section>
    <section class="column"></section>
  </main>
</template>
