import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column main" }
const _hoisted_4 = { class: "column" }

import { onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { MenuGroup } from '../models/navigation.model';
import { AuthLevel } from '../models/auth.model';
import { Actions, Mutations } from '@/models/store.model';
import Search from '@/components/list/Search.vue';
import Categories from '@/components/list/Categories.vue';
import RecipeList from '@/components/list/RecipeList.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import PageTitle from '@/components/common/PageTitle.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'List',
  setup(__props) {

const store = useStore();
const route = useRoute();

const update = async () => {
  const slug = route.params.slug;
  await store.dispatch(Actions.SelectCategoryBySlug, slug);
};

watch(route, update);

onMounted(async () => {
  await update();

  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Nieuw recept toevoegen',
      target: '/recipe/new/edit',
      group: MenuGroup.List,
      level: AuthLevel.Chef,
    },
    {
      label: 'Gebruikers beheren',
      target: '/admin/',
      group: MenuGroup.List,
      level: AuthLevel.Admin,
    },
  ]);
});

onUnmounted(() => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.List);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageTitle),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(Search),
        _createVNode(Categories)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(RecipeList)
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createVNode(PageMenu)
      ])
    ])
  ], 64))
}
}

})