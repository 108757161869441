import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = ["title", "onClick"]

import { computed, ref } from 'vue';
import { toolbarConfig, ToolbarConfig } from '../toolbar.config';
import { mapState, useStore } from 'vuex';
import { JigsawState } from '../../store';


export default /*@__PURE__*/_defineComponent({
  __name: 'JigsawToolbar',
  setup(__props) {

const config = ref<ToolbarConfig>(toolbarConfig);

const store = useStore();
const editor = computed(() => store.state.editor);

const activate = (commandName: string) => {
  // console.log(commandName);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(config.value, (group) => {
      return (_openBlock(), _createElementBlock("div", {
        key: group.name,
        class: "toolbar-group"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item) => {
          return (_openBlock(), _createElementBlock("a", {
            key: item.label,
            href: "#",
            class: "toolbar-item",
            title: item.label,
            onClick: _withModifiers(($event: any) => (activate(item.command)), ["prevent"])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`icon-${item.icon}`)
            }, null, 2)
          ], 8, _hoisted_2))
        }), 128))
      ]))
    }), 128))
  ]))
}
}

})