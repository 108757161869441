import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "box box--tertiary" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "form-item" }
const _hoisted_6 = {
  id: "addnote",
  class: "form-buttons note last-note"
}

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Recipe } from '@/models/recipe.model';
import { Actions } from '@/models/store.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeNotes',
  setup(__props) {

const store = useStore();
const recipe = computed<Recipe>(() => store.state.recipe);

const showForm = ref<boolean>(false);
const note = ref({
  author: '',
  paragraph: '',
});

const addNote = async () => {
  if (note.value.paragraph.length === 0) {
    return;
  }
  const result = await store.dispatch(Actions.AddNote, {
    recipe: recipe.value,
    note: { ...note.value, paragraph: note.value.paragraph.split('\n') },
  });

  if (result) {
    note.value = {
      author: '',
      paragraph: '',
    };
    showForm.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", null, "Notities voor " + _toDisplayString(recipe.value.name), 1),
    (!recipe.value.notes)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Geen notities..."))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recipe.value.notes, (note, index) => {
      return (_openBlock(), _createElementBlock("section", {
        class: "note",
        key: index
      }, [
        _createElementVNode("div", {
          class: "author",
          textContent: _toDisplayString(note.author)
        }, null, 8, _hoisted_3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(note.paragraph, (p) => {
          return (_openBlock(), _createElementBlock("p", {
            key: p,
            textContent: _toDisplayString(p)
          }, null, 8, _hoisted_4))
        }), 128))
      ]))
    }), 128)),
    _createElementVNode("form", {
      class: _normalizeClass(["noteform", { hidden: !showForm.value }]),
      id: "noteform",
      method: "post",
      onSubmit: _withModifiers(addNote, ["prevent"])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "description" }, "Voeg een notitie toe.", -1)),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { for: "notetext" }, [
          _createElementVNode("span", { class: "white" }, "Notitie")
        ], -1)),
        _withDirectives(_createElementVNode("textarea", {
          rows: "5",
          id: "notetext",
          name: "note",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((note.value.paragraph) = $event))
        }, null, 512), [
          [_vModelText, note.value.paragraph]
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-buttons" }, [
        _createElementVNode("button", { type: "submit" }, "Toevoegen")
      ], -1))
    ], 34),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (showForm.value = true))
      }, "Notitie maken")
    ], 512), [
      [_vShow, !showForm.value]
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "icon icon-beans" }, null, -1))
  ]))
}
}

})