import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-item form-name-input" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-item form-name-input" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "form-item form-name-input" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "form-item form-name-input" }

import { PropType, ref } from 'vue';
import { InputComposable } from '../../recipe/form/input.composable';

interface Names {
  name_nl: string;
  name_en?: string;
  name_id?: string;
  name_pref?: string;
  keywords: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NameInput',
  props: {
  modelValue: {
    type: Object as PropType<Names>,
    default: () => ({ name_nl: '', name_en: '', name_id: '', keywords: [] }),
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const showVariations = ref<boolean>(false);



const emit = __emit;
const { val, update } = InputComposable<Names>(__props.modelValue, emit);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showVariations.value = !showVariations.value), ["prevent"]))
        }, "Name (Nederlands)")
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(val).name_nl) = $event)),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
      }, null, 544), [
        [_vModelText, _unref(val).name_nl]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(val).name_pref = 'nl'))
      }, [
        (_unref(val).name_pref === 'nl')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "★"))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, "☆"))
      ])
    ]),
    (showVariations.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[12] || (_cache[12] = _createElementVNode("label", null, "Name (English)", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(val).name_en) = $event)),
              onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
            }, null, 544), [
              [_vModelText, _unref(val).name_en]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(val).name_pref = 'en'))
            }, [
              (_unref(val).name_pref === 'en')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "★"))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, "☆"))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[13] || (_cache[13] = _createElementVNode("label", null, "Name (Indonesian)", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(val).name_id) = $event)),
              onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
            }, null, 544), [
              [_vModelText, _unref(val).name_id]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(val).name_pref = 'id'))
            }, [
              (_unref(val).name_pref === 'id')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "★"))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, "☆"))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[14] || (_cache[14] = _createElementVNode("label", null, "Keywords", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(val).keywords) = $event)),
              onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
            }, null, 544), [
              [_vModelText, _unref(val).keywords]
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})