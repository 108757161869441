import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }

import Version from '@/components/common/Version.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PageTitle',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h1", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" Moms Lost Marbles ")),
        _createVNode(Version)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "tagline" }, " Recepten van de familie Giesberts, Drost, Weber en anderen ", -1))
    ])
  ]))
}
}

})