import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box box--secondary"
}
const _hoisted_2 = { class: "changes" }
const _hoisted_3 = ["textContent"]

import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { Recipe } from '@/models/recipe.model';
import { Change, changeText } from '@/models/changes.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { longDate } from '@/components/common/filters/date.filter';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeChanges',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {

const store = useStore();


const changes = ref<Change[]>([]);

const update = async () => {
  const data = await store.dispatch(Actions.GetRecipeChangeLog, __props.recipe);
  changes.value = data ? data : [];
  store.dispatch(Actions.GetUsers);
};

watch(() => __props.recipe, update);

onMounted(async () => {
  await update();
});

return (_ctx: any,_cache: any) => {
  return (changes.value.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Wijzigingen", -1)),
        _createElementVNode("ol", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(changes.value, (change) => {
            return (_openBlock(), _createElementBlock("li", {
              key: change.id
            }, [
              _createTextVNode(_toDisplayString(_unref(longDate)(change.date)) + " ", 1),
              _createElementVNode("span", {
                textContent: _toDisplayString(`${change.user}`)
              }, null, 8, _hoisted_3),
              _createTextVNode(" " + _toDisplayString(_unref(changeText)(change.type, __props.recipe.name)) + ". ", 1)
            ]))
          }), 128))
        ]),
        _createVNode(Icon, { name: "icecream" })
      ]))
    : _createCommentVNode("", true)
}
}

})