import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wide columns" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }

import MenuAnalysis from '@/components/menu/MenuAnalysis.vue';
import MealRefForm from '@/components/menu/MealRefForm.vue';
import MenuForm from '@/components/menu/MenuForm.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AnalyzeMenu',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(MenuAnalysis)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(MenuForm)
    ])
  ]))
}
}

})