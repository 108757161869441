import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "preparation" }

import RecipeStep from '@/components/recipe/RecipeStep.vue';
import { Recipe } from '@/models/recipe.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeSteps',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Bereidingswijze", -1)),
    _createElementVNode("ol", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.recipe.steps, (step, index) => {
        return (_openBlock(), _createBlock(RecipeStep, {
          class: "step",
          key: index,
          step: step.contents
        }, null, 8, ["step"]))
      }), 128))
    ])
  ]))
}
}

})