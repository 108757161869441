import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["value", "textContent"]

import { PropType } from 'vue';
import { Category } from '@/models/category.model';
import { InputComposable } from './input.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeSelect',
  props: {
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<Category[]>,
    default: () => [],
  },
  modelValue: {
    type: Number,
    default: '',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { val, update } = InputComposable<number>(__props.modelValue, emit);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      textContent: _toDisplayString(__props.label)
    }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(val) ? (val).value = $event : null)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (cat) => {
        return (_openBlock(), _createElementBlock("option", {
          key: cat.id,
          value: cat.id,
          textContent: _toDisplayString(cat.name_singular)
        }, null, 8, _hoisted_3))
      }), 128))
    ], 544), [
      [_vModelSelect, _unref(val)]
    ])
  ]))
}
}

})