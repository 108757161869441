import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-item form-multiple" }
const _hoisted_2 = { class: "field-option" }

import { PropType } from 'vue';
import { Ingredient } from '@/models/recipe.model';
import { InputComposable } from './input.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeIngredientFields',
  props: {
  index: {
    type: Number,
    default: -1,
  },
  modelValue: {
    type: Object as PropType<Ingredient>,
    default: () => ({ name: '' }),
  },
},
  emits: ['update:modelValue', 'remove'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const { val, update } = InputComposable<Ingredient>(__props.modelValue, emit);

const removeIngredient = (index: number) => {
  emit('remove', index);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("label", null, null, -1)),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Hoeveelheid",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(val).amount) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
    }, null, 544), [
      [_vModelText, _unref(val).amount]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Naam",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(val).name) = $event)),
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
    }, null, 544), [
      [_vModelText, _unref(val).name]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Opmerking",
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(val).remark) = $event)),
      onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
    }, null, 544), [
      [_vModelText, _unref(val).remark]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "remove",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (removeIngredient(__props.index)))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("i", { class: "icon icon-close" }, null, -1)
      ]))
    ])
  ]))
}
}

})