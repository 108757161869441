import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box recipe" }
const _hoisted_2 = ["textContent"]

import { Recipe } from '@/models/recipe.model';
import RecipeInfo from '@/components/recipe/RecipeInfo.vue';
import RecipeSummary from '@/components/recipe/RecipeSummary.vue';
import RecipeSteps from '@/components/recipe/RecipeSteps.vue';
import CookingMode from '@/components/recipe/CookingMode.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeContents',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      textContent: _toDisplayString(__props.recipe.name)
    }, null, 8, _hoisted_2),
    _createVNode(CookingMode),
    _createVNode(RecipeInfo, { recipe: __props.recipe }, null, 8, ["recipe"]),
    _createVNode(RecipeSummary, { recipe: __props.recipe }, null, 8, ["recipe"]),
    _createVNode(RecipeSteps, { recipe: __props.recipe }, null, 8, ["recipe"])
  ]))
}
}

})