import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item" }
const _hoisted_3 = { class: "form-item" }

import { User } from '@/models/user.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFields',
  props: {
  user: {
    type: User,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { for: "focus" }, "Naam", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "(naam)",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((__props.user.name) = $event))
      }, null, 512), [
        [_vModelText, __props.user.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "focus" }, "Email", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "(email)",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((__props.user.email) = $event))
      }, null, 512), [
        [_vModelText, __props.user.email]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[6] || (_cache[6] = _createElementVNode("label", { for: "focus" }, "Type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((__props.user.user_level) = $event))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("option", { value: "0" }, "Kok", -1),
        _createElementVNode("option", { value: "50" }, "Chef", -1),
        _createElementVNode("option", { value: "100" }, "Administrator", -1)
      ]), 512), [
        [
          _vModelSelect,
          __props.user.user_level,
          void 0,
          { number: true }
        ]
      ])
    ])
  ]))
}
}

})