<script lang="ts" setup></script>
<template>
  <main class="page-wide columns">
    <section class="column">
      <ul>
        <li>Meal</li>
        <li>Edit option</li>
        <li>Used in which weekmenus</li>
      </ul>
      <Meal></Meal>
    </section>
    <section class="column">
      <MealForm></MealForm>
    </section>
  </main>
</template>
