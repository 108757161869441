import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box box--tertiary" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageMenu',
  setup(__props) {

const store = useStore();
const menu = computed(() => store.state.menu);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Opties", -1)),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.value, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${option.label}-${option.group}`
        }, [
          _createVNode(_component_router_link, {
            to: option.target,
            textContent: _toDisplayString(option.label)
          }, null, 8, ["to", "textContent"])
        ]))
      }), 128))
    ]),
    _createVNode(Icon, { name: "bananas" })
  ]))
}
}

})