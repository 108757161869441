import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["type", "placeholder"]

import { InputComposable } from './input.composable';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeField',
  props: {
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: String,
    default: '',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const { val, update } = InputComposable<string>(__props.modelValue, emit);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      textContent: _toDisplayString(__props.label)
    }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      type: __props.type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(val) ? (val).value = $event : null)),
      placeholder: __props.label,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(update) && _unref(update)(...args)))
    }, null, 40, _hoisted_3), [
      [_vModelDynamic, _unref(val)]
    ])
  ]))
}
}

})