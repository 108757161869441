import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "box box--secondary" }
const _hoisted_2 = { key: 0 }

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Categories',
  setup(__props) {

const store = useStore();

const category_id = computed(() => store.state.category_id);
const categories = computed(() => store.state.categories);

onMounted(async () => {
  await store.dispatch(Actions.GetCategories);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Categoriën", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, "Kies een categorie.", -1)),
    (category_id.value !== -1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/list" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Alle recepten")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category) => {
        return (_openBlock(), _createElementBlock("li", {
          key: category.id
        }, [
          _createVNode(_component_router_link, {
            to: `/list/category/${category.slug}`,
            class: _normalizeClass({ selected: category.id === category_id.value }),
            textContent: _toDisplayString(category.name_plural)
          }, null, 8, ["to", "class", "textContent"])
        ]))
      }), 128))
    ]),
    _createVNode(Icon, { name: "melon" })
  ]))
}
}

})