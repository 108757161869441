import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box box--secondary"
}

import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Change } from '@/models/changes.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { longDate } from '@/components/common/filters/date.filter';


export default /*@__PURE__*/_defineComponent({
  __name: 'LatestChange',
  setup(__props) {

const store = useStore();
const latestChange = ref<Change | null>(null);

onMounted(async () => {
  latestChange.value = await store.dispatch(Actions.GetLatestChange);
});

return (_ctx: any,_cache: any) => {
  return (latestChange.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Wijzigingen", -1)),
        _createElementVNode("p", null, _toDisplayString(latestChange.value.user) + " heeft wijzigingen gemaakt aan " + _toDisplayString(latestChange.value.recipe) + " op " + _toDisplayString(_unref(longDate)(latestChange.value.date)) + ". ", 1),
        _createVNode(Icon, { name: "beans" })
      ]))
    : _createCommentVNode("", true)
}
}

})