import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box box--secondary" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Recipe } from '@/models/recipe.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeCategory',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const categoryRecipes = computed<Recipe[]>(() =>
  store.state.recipes.filter(
    (r: Recipe) => r.category_id === __props.recipe.category_id,
  ),
);

const categoryName = (id: number, plural: boolean) =>
  store.getters.categoryName(id, plural);

onMounted(async () => {
  await store.dispatch(Actions.GetCategories);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      textContent: _toDisplayString(categoryName(__props.recipe.category_id, categoryRecipes.value.length > 1))
    }, null, 8, _hoisted_2),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryRecipes.value, (rec) => {
        return (_openBlock(), _createElementBlock("li", {
          key: rec.id
        }, [
          (rec.id === __props.recipe.id)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                textContent: _toDisplayString(rec.name)
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: `/recipe/${rec.slug}`,
                textContent: _toDisplayString(rec.name)
              }, null, 8, ["to", "textContent"]))
        ]))
      }), 128))
    ]),
    _createVNode(Icon, { name: "milk" })
  ]))
}
}

})