import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "summary-text" }
const _hoisted_3 = { class: "category" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "date" }
const _hoisted_7 = ["textContent"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { Recipe } from '@/models/recipe.model';
import { MomsState } from '@/models/store.model';
import { longDate } from '@/components/common/filters/date.filter';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeSummary',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {

const store = useStore<MomsState>();

const image = computed(() => {
  return __props.recipe.image ? `/img/recipe_large/${__props.recipe.image}` : '';
});
const persons = computed(() => {
  return parseInt(__props.recipe.servings, 10) === 1 ? 'persoon' : 'personen';
});
const categoryName = (id: number, plural: boolean) =>
  store.getters.categoryName(id, plural);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (image.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "image",
          src: image.value,
          align: "right"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("span", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" Een ")),
        _createElementVNode("span", _hoisted_3, _toDisplayString(categoryName(__props.recipe.category_id, false)), 1)
      ]),
      (__props.recipe.cook)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            textContent: _toDisplayString(` van ${__props.recipe.cook}`)
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (__props.recipe.servings !== '0')
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            textContent: _toDisplayString(` voor ${__props.recipe.servings} ${persons.value}`)
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createTextVNode(". Toegevoegd op ")),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(longDate)(__props.recipe.creation_date)) + ".", 1),
      (__props.recipe.preparation_time)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            textContent: _toDisplayString(` De bereidingstijd bedraagt ${__props.recipe.preparation_time}.`)
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})