import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "field-urls" }
const _hoisted_2 = { class: "field-url" }
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["onClick"]

import { PropType, reactive } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UrlsInput',
  props: {
  modelValue: {
    required: true,
    type: Array as PropType<string[]>,
    default: [],
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const urls = reactive<string[]>(props.modelValue);

const addUrl = () => {
  urls.push('');
  update();
};

const removeUrl = (index: number) => {
  urls.splice(index, 1);
  update();
};

const update = () => {
  emit('update:modelValue', urls);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(urls, (_, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": ($event: any) => ((urls[index]) = $event),
          onChange: update
        }, null, 40, _hoisted_3), [
          [_vModelText, urls[index]]
        ]),
        _createElementVNode("button", {
          onClick: _withModifiers(($event: any) => (removeUrl(index)), ["stop","prevent"])
        }, "-", 8, _hoisted_4)
      ]))
    }), 256)),
    _createElementVNode("p", null, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (addUrl()), ["stop","prevent"]))
      }, "Add url")
    ])
  ]))
}
}

})