import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box"
}
const _hoisted_2 = { class: "box-option" }
const _hoisted_3 = { class: "week-menu" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "shopping-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "box"
}

import { computed, markRaw, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  ParsedMenuDay,
  ParsedMenu,
  ParsedIngredient,
  Ingredient,
  Meal,
  NEW_ITEM_ID,
  getIngredient,
  getMeal,
} from '@/models/menu.model';
import { MenuActions, MenuMutations } from './menu.store';
import { ModalMutations } from '../common/modal/modal.store';
import MealForm from './MealForm.vue';
import ChooseMealForm from './ChooseMealForm.vue';
import IngredientForm from './IngredientForm.vue';
import ChooseIngredientForm from './ChooseIngredientForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuAnalysis',
  emits: ['selectMeal'],
  setup(__props, { emit: __emit }) {

const store = useStore();

const ingredients = computed<Ingredient[]>(() => store.state.us.ingredients);
const meals = computed<Meal[]>(() => store.state.us.meals);

const parsed = computed<ParsedMenu>(() => store.state.us.parsedMenu);

const emit = __emit;

onMounted(async () => {
  await store.dispatch(MenuActions.GetIngredients);
  await store.dispatch(MenuActions.GetMeals);
  await store.dispatch(MenuActions.AnalyzeMenu);
});

const updateAnalyzed = async (id: number) => {
  await store.dispatch(MenuActions.UpdateAnalyzedMenu, id);
};

const selectMeal = (meal: ParsedMenuDay) => {
  const stored = getMeal(meal.meal, meals.value);

  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(MealForm),
  });
  store.commit(
    MenuMutations.EditMeal,
    stored || { name_nl: meal.meal, recipe_urls: meal.urls },
  );
};

const selectIngredient = (ingredient: ParsedIngredient) => {
  const stored = getIngredient(ingredient.name, ingredients.value);
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(IngredientForm),
  });
  store.commit(
    MenuMutations.EditIngredient,
    stored || { name_nl: ingredient.name },
  );
};

const addIngredient = () => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(IngredientForm),
  });
  store.commit(MenuMutations.EditIngredient, { id: NEW_ITEM_ID, name_nl: '' });
};
const editIngredient = () => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(ChooseIngredientForm),
  });
};

const addMeal = () => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(MealForm),
  });
  store.commit(MenuMutations.EditMeal, { id: NEW_ITEM_ID, name_nl: '' });
};
const editMeal = () => {
  store.commit(ModalMutations.OpenModal, {
    modal: markRaw(ChooseMealForm),
  });
};

const isStoredIngredient = (name: string): boolean =>
  !!getIngredient(name, ingredients.value);
const isStoredMeal = (name: string): boolean => !!getMeal(name, meals.value);

const days: (
  | 'saturday'
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
)[] = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
];

const capitalize = (str: string) =>
  `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (parsed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", null, "Analyze " + _toDisplayString(parsed.value.subject) + " - " + _toDisplayString(parsed.value.year), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "secondary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (updateAnalyzed(parsed.value.id)), ["prevent"]))
            }, " Update "),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (addMeal()), ["prevent"]))
              }, "Add meal")
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (editMeal()), ["prevent"]))
              }, "Edit meal")
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (addIngredient()), ["prevent"]))
              }, "Add ingredient")
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (editIngredient()), ["prevent"]))
              }, "Edit ingredient")
            ])
          ]),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(days, (day) => {
              return _createElementVNode("li", { key: day }, [
                _createElementVNode("span", null, _toDisplayString(capitalize(day)) + ":", 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (selectMeal(parsed.value[day])), ["prevent"])
                }, _toDisplayString(parsed.value[day].meal), 9, _hoisted_4),
                _cache[5] || (_cache[5] = _createTextVNode("  ")),
                (isStoredMeal(parsed.value[day].meal))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "✔"))
                  : _createCommentVNode("", true)
              ])
            }), 64))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Kopen", -1)),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parsed.value.ingredients, (ing) => {
              return (_openBlock(), _createElementBlock("li", {
                key: ing.name
              }, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _withModifiers(($event: any) => (selectIngredient(ing)), ["prevent"])
                }, [
                  _createTextVNode(_toDisplayString(ing.name) + " ", 1),
                  (isStoredIngredient(ing.name))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "✔"))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_7)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (parsed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, _toDisplayString(meals.value.length) + " Meals", 1),
            _createElementVNode("li", null, _toDisplayString(ingredients.value.length) + " Ingredients", 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})