import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "node-toolbar" }
const _hoisted_2 = ["textContent"]

import { computed } from 'vue';
import { mapState, useStore } from 'vuex';
import { JigsawEditor } from '../../jigsaw';
import JigsawToolbarItem from './JigsawToolbarItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'JigsawNodeToolbar',
  setup(__props) {

const store = useStore();
const editor = computed<JigsawEditor>(() => store.state.editor);
const context = computed(() => store.state.context);

const path = computed(() => {
  if (context) {
    return context.value.getPath().map((n) => n.name);
  }
  return editor.value.getPath().map((n) => n.name);
});

const getPrettyName = (node) => {
  return editor.value.nodeConfig.getNodePrettyName(node);
};
const activate = (f) => {
  // console.log(f);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(path.value, (item) => {
      return (_openBlock(), _createBlock(JigsawToolbarItem, { key: item }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            textContent: _toDisplayString(getPrettyName(item))
          }, null, 8, _hoisted_2)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}
}

})