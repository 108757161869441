import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-option" }

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Recipe } from '@/models/recipe.model';
import RecipeField from './RecipeField.vue';
import RecipeSelect from './RecipeSelect.vue';
import RecipeFormIngredients from './RecipeFormIngredients.vue';
import RecipeFormSteps from './RecipeFormSteps.vue';
import { Actions } from '@/models/store.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeForm',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  emits: ['updateRecipe', 'cancel'],
  setup(__props, { emit: __emit }) {



const store = useStore();
const router = useRouter();
const updated = ref<Recipe | null>(null);
const categories = computed(() => store.state.categories);
const isAdmin = computed(() => store.getters.isAdmin);
const emit = __emit;

onMounted(() => {
  updated.value = __props.recipe;
});

const submit = () => {
  emit('updateRecipe', updated.value);
};

const cancel = () => {
  emit('cancel');
};

const remove = async () => {
  if (window.confirm('Are you sure you want to remove this recipe')) {
    const ok = await store.dispatch(Actions.RemoveRecipe, __props.recipe.id);
    if (ok) {
      router.push('/list');
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (__props.recipe && updated.value)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "box",
        onSubmit: _withModifiers(submit, ["prevent"])
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Recept bewerken", -1)),
        _createVNode(RecipeSelect, {
          label: "Categorie",
          modelValue: updated.value.category_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((updated.value.category_id) = $event)),
          options: categories.value
        }, null, 8, ["modelValue", "options"]),
        _createVNode(RecipeField, {
          label: "Titel",
          modelValue: updated.value.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((updated.value.name) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(RecipeField, {
          label: "Kok",
          modelValue: updated.value.cook,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((updated.value.cook) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(RecipeField, {
          label: "Bereidingstijd",
          modelValue: updated.value.preparation_time,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((updated.value.preparation_time) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(RecipeField, {
          label: "Aantal porties",
          modelValue: updated.value.servings,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((updated.value.servings) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(RecipeFormIngredients, {
          label: "Ingrediënten",
          modelValue: updated.value.ingredients,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((updated.value.ingredients) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(RecipeFormSteps, {
          label: "Voorbereiding",
          modelValue: updated.value.steps,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((updated.value.steps) = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_1, [
          _cache[8] || (_cache[8] = _createElementVNode("button", { type: "submit" }, [
            _createElementVNode("i", { class: "icon icon-approve" }),
            _createTextVNode(" Opslaan ")
          ], -1)),
          (isAdmin.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                onClick: remove
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "icon icon-forbidden" }, null, -1),
                _createTextVNode(" Remove ")
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            type: "button",
            onClick: cancel
          }, "Stoppen")
        ])
      ], 32))
    : _createCommentVNode("", true)
}
}

})