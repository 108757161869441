import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box box--tertiary box-modal" }
const _hoisted_2 = { class: "form-item" }
const _hoisted_3 = { class: "form-buttons" }

import { Ingredient } from '@/models/menu.model';
import { useStore } from 'vuex';
import { MenuMutations } from './menu.store';
import IngredientForm from './IngredientForm.vue';
import { ModalMutations } from '../common/modal/modal.store';
import { computed, markRaw, ref } from 'vue';
import Autocomplete, { Item } from './form/Autocomplete.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseIngredientForm',
  setup(__props) {

const store = useStore();

const ingredient_id = ref<number>(-1);

const ingredientItems = computed<Item[]>(() =>
  store.state.us.ingredients.map((ing: Ingredient): Item => {
    return {
      label: ing.name_nl,
      search: `${ing.name_en || ''}, ${ing.name_id || ''}, ${(
        ing.keywords || []
      ).join(',')}`,
      value: ing.id,
    };
  }),
);

const selectIngredient = () => {
  const m = store.state.us.ingredients.find(
    (m: Ingredient) => m.id === ingredient_id.value,
  );
  if (m) {
    cancel();
    store.commit(ModalMutations.OpenModal, {
      modal: markRaw(IngredientForm),
    });
    store.commit(MenuMutations.EditIngredient, m);
  }
};

const cancel = () => {
  store.commit(ModalMutations.CloseModal);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Select a ingredient", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("label", null, "Ingredient", -1)),
      _createVNode(Autocomplete, {
        modelValue: ingredient_id.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ingredient_id).value = $event)),
        items: ingredientItems.value,
        class: "large"
      }, null, 8, ["modelValue", "items"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[3] || (_cache[3] = _createElementVNode("label", null, null, -1)),
      _createElementVNode("button", {
        type: "button",
        onClick: cancel
      }, "Cancel"),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (selectIngredient()), ["prevent"]))
      }, "Edit")
    ])
  ]))
}
}

})