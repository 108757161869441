import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "editor"
}

import { ref } from 'vue';
import { useStore } from 'vuex';
import { Mutations } from './store';
import { JigsawEditor } from './jigsaw';
import JigsawToolbar from '@/jigsaw/components/toolbar/JigsawToolbar.vue';
import EditableContent from '@/jigsaw/components/EditableContent.vue';
import JigsawContextMenu from '@/jigsaw/components/menu/JigsawContextMenu.vue';
import JigsawNodeToolbar from '@/jigsaw/components/toolbar/JigsawNodeToolbar.vue';
import { EditorEvent } from './core/event-emitter';


export default /*@__PURE__*/_defineComponent({
  __name: 'Jigsaw',
  props: {
  xml: {
    type: String,
    required: true,
  },
  stylesheet: {
    type: String,
    required: true,
  },
  schema: {
    type: String,
    required: true,
  },
  config: {
    type: String,
    required: true,
  },
},
  setup(__props) {



const ready = ref<boolean>(true);

const editor = new JigsawEditor(
  __props.xml as string,
  __props.stylesheet as string,
  __props.schema as string,
  __props.config as string,
);

editor.on('initialized', () => {
  ready.value = true;
});

const store = useStore();

store.commit(`jigsaw/${[Mutations.SetEditor]}`, editor);

editor.on('changedSelection', (selection: EditorEvent) => {
  store.commit(`jigsaw/${[Mutations.SetSelection]}`, selection);
});
editor.on('changedFocus', (evt: EditorEvent) => {
  store.commit(`jigsaw/${[Mutations.SetContext]}`, evt.data);
});

return (_ctx: any,_cache: any) => {
  return (ready.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(JigsawToolbar),
        _createVNode(EditableContent),
        _createVNode(JigsawContextMenu),
        _createVNode(JigsawNodeToolbar)
      ]))
    : _createCommentVNode("", true)
}
}

})