import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import versionInfo from '@/version.json';
import { Version } from '@/models/version.model';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Version',
  setup(__props) {

const v = new Version(versionInfo);
const version = computed(() => `Version ${v.version}`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: "version",
    textContent: _toDisplayString(version.value)
  }, null, 8, _hoisted_1))
}
}

})