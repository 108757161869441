import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "form-item" }

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { User } from '@/models/user.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { vFocus } from '@/components/common/directives/focus.directive';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginAdmin',
  setup(__props) {

const store = useStore();
const router = useRouter();
const auth = ref<Partial<User>>({ password: '' });
const redirect = store.state.redirect;

const submit = async () => {
  const status = await store.dispatch(Actions.Login, {
    type: 'admin',
    auth: auth.value,
  });

  if (status) {
    router.push(redirect ? redirect : '/list');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(submit, ["prevent"])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Inloggen als administrator", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "description" }, " Om gebruikers te kunnen beheren moet je eerst inloggen met een wachtwoord. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "focus" }, "Wachtwoord", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((auth.value.password) = $event)),
          placeholder: "(watchwoord)"
        }, null, 512), [
          [_vModelText, auth.value.password],
          [_unref(vFocus), true]
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-buttons" }, [
        _createElementVNode("label"),
        _createElementVNode("button", { type: "submit" }, "Inloggen")
      ], -1))
    ], 32)
  ]))
}
}

})