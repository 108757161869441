import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box box--tertiary"
}
const _hoisted_2 = { class: "form-buttons" }
const _hoisted_3 = { type: "submit" }

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { User } from '@/models/user.model';
import { Actions, Mutations } from '@/models/store.model';
import UserFields from './UserFields.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditUser',
  setup(__props) {

const store = useStore();
const user = ref<User | null>(null);
const edit_user = computed<User>(() => store.state.edit_user);

const update = () => {
  user.value = edit_user.value ? edit_user.value.clone() : null;
};

watch(edit_user, update);
onMounted(() => update);

const close = () => {
  store.commit(Mutations.SetEditUser, null);
};
const submit = () => {
  if (edit_user.value.id === -1) {
    store.dispatch(Actions.SaveUser, user.value);
  } else {
    store.dispatch(Actions.UpdateUser, user.value);
  }
};

const action = computed<string>(() =>
  edit_user.value.id === -1 ? 'toevoegen' : 'wijzigen',
);

const capitalize = (text: string) =>
  `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`;

return (_ctx: any,_cache: any) => {
  return (user.value)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (close()), ["prevent"])),
          class: "box__close"
        }, "𝖷"),
        _createElementVNode("form", {
          onSubmit: _withModifiers(submit, ["prevent"])
        }, [
          _createElementVNode("h2", null, "Gebruiker " + _toDisplayString(action.value), 1),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "description" }, "Wijzig de details van de gebruiker", -1)),
          _createVNode(UserFields, { user: user.value }, null, 8, ["user"]),
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("label", null, null, -1)),
            _createElementVNode("button", _hoisted_3, _toDisplayString(capitalize(action.value)), 1)
          ])
        ], 32),
        _cache[3] || (_cache[3] = _createElementVNode("dl", null, [
          _createElementVNode("dt", null, "Kok"),
          _createElementVNode("dd", null, "Een kok kan de recepten alleen lezen."),
          _createElementVNode("dt", null, "Chef"),
          _createElementVNode("dd", null, "Een chef kan de recepten lezen en wijzigen."),
          _createElementVNode("dt", null, "Administrator"),
          _createElementVNode("dd", null, " Een administrator kan de recepten lezen en wijzigen en ook de gebruikers beheren. ")
        ], -1))
      ]))
    : _createCommentVNode("", true)
}
}

})