import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-container"
}

import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ModalInfo, ModalMutations } from './modal.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  setup(__props) {

const store = useStore();
const modal = computed<ModalInfo | null>(() => store.state.ms.modal);
const show = ref<boolean>(false);

watch(modal, (modal: ModalInfo | null) => {
  if (modal) {
    show.value = true;
  }
});

const close = () => {
  show.value = false;
  store.commit(ModalMutations.CloseModal);
};

return (_ctx: any,_cache: any) => {
  return (modal.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("section", {
          class: _normalizeClass(["modal", { show: show.value }])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(modal.value.modal), {
            onClose: close,
            data: modal.value.data
          }, null, 40, ["data"]))
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
}

})