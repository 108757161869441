import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "form-item" }

import { useStore } from 'vuex';
import { ModalMutations } from '../common/modal/modal.store';
import { PropType, computed, reactive } from 'vue';
import { Meal, IngredientRef } from '@/models/menu.model';
import Autocomplete, { Item } from './form/Autocomplete.vue';
import { MenuMutations } from './menu.store';
import Autosuggest from './form/Autosuggest.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'IngredientRefForm',
  props: {
  data: {
    type: Object as PropType<{ index: number; ref: IngredientRef }>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const store = useStore();

const ingredientRef = reactive<IngredientRef>({ ...props.data.ref });

const ingredientItems = computed<Item[]>(() =>
  store.state.us.ingredients.map((ing: Meal): Item => {
    return {
      label: ing.name_nl,
      search: `${ing.name_en || ''}, ${ing.name_id || ''}, ${(
        ing.keywords || []
      ).join(',')}`,
      value: ing.id,
    };
  }),
);

const unitItems: Item[] = [
  { label: 'stuks', value: 'x' },
  { label: 'gram', value: 'gr' },
  { label: 'kilogram', value: 'kg' },
  { label: 'liter', value: 'l' },
  { label: 'deciliter', value: 'dl' },
  { label: 'milliliter', value: 'ml' },
  { label: 'blik', value: 'blik' },
  { label: 'doos', value: 'doos' },
];

const notesSuggestions: string[] = ['van Dirk', 'groen', 'klein'];

const cancel = () => {
  store.commit(ModalMutations.CloseModal);
};

const submit = () => {
  store.commit(MenuMutations.UpdateMenuShoppingList, {
    index: props.data.index,
    ingredientRef,
  });
  cancel();
};

return (_ctx: any,_cache: any) => {
  return (props.data)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "box box--tertiary box-modal",
        onSubmit: _withModifiers(submit, ["prevent"])
      }, [
        _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Choose ingredient", -1)),
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Ingredient", -1)),
          _createVNode(Autocomplete, {
            modelValue: ingredientRef.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ingredientRef.id) = $event)),
            items: ingredientItems.value,
            class: "large"
          }, null, 8, ["modelValue", "items"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", null, "Amount", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "small",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ingredientRef.amount) = $event))
          }, null, 512), [
            [_vModelText, ingredientRef.amount]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("label", null, "Unit", -1)),
          _createVNode(Autocomplete, {
            modelValue: ingredientRef.unit,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ingredientRef.unit) = $event)),
            items: unitItems,
            class: "small"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Notes", -1)),
          _createVNode(Autosuggest, {
            modelValue: ingredientRef.notes,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ingredientRef.notes) = $event)),
            items: notesSuggestions
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", { class: "form-buttons" }, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, null, -1)),
          _createElementVNode("button", {
            type: "button",
            onClick: cancel
          }, "Cancel"),
          _cache[9] || (_cache[9] = _createElementVNode("button", { type: "submit" }, "Save", -1))
        ])
      ], 32))
    : _createCommentVNode("", true)
}
}

})