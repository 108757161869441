import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "date" }

import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Recipe } from '@/models/recipe.model';
import { Actions } from '@/models/store.model';
import Icon from '@/components/common/Icon.vue';
import { longDate } from '@/components/common/filters/date.filter';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewRecipes',
  setup(__props) {

const store = useStore();
const categoryName = (id: number, plural: boolean) =>
  store.getters.categoryName(id, plural);
const recipes = ref<Recipe[]>([]);

const persons = (recipe: Recipe): string => {
  return parseInt(recipe.servings) === 1 ? 'persoon' : 'personen';
};
const servings = (recipe: Recipe): string => {
  return recipe.servings ? ` voor ${recipe.servings} ${persons(recipe)}` : '';
};

onMounted(async () => {
  await store.dispatch(Actions.GetCategories);
  recipes.value = await store.dispatch(Actions.GetNewRecipes);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Nieuwe recepten", -1)),
    _createElementVNode("ol", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recipes.value, (recipe) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "item",
          key: recipe.id
        }, [
          _createElementVNode("h4", {
            textContent: _toDisplayString(recipe.name)
          }, null, 8, _hoisted_3),
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode(" Een ")),
            _createElementVNode("span", {
              class: "category",
              textContent: _toDisplayString(categoryName(recipe.category_id, false))
            }, null, 8, _hoisted_4),
            (recipe.cook)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " van " + _toDisplayString(recipe.cook), 1))
              : _createCommentVNode("", true),
            (recipe.servings)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(servings(recipe)), 1))
              : _createCommentVNode("", true),
            _cache[1] || (_cache[1] = _createTextVNode(". Toegevoegd op ")),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(longDate)(recipe.creation_date)), 1)
          ])
        ]))
      }), 128))
    ]),
    _createVNode(Icon, { name: "broccoli" })
  ]))
}
}

})