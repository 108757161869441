import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column main-large" }
const _hoisted_3 = { class: "column" }

import { onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { MenuGroup } from '@/models/navigation.model';
import { AuthLevel } from '../models/auth.model';
import PageMenu from '@/components/common/PageMenu.vue';
import LoginChef from '@/components/user/LoginChef.vue';
import LoginAdmin from '@/components/user/LoginAdmin.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import { Mutations } from '@/models/store.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const store = useStore();
const route = useRoute();
const type = ref<string>('chef');

onMounted(() => {
  type.value = route.params.type as string;

  store.commit(Mutations.AddMenuItems, [
    {
      label: 'Terug naar de lijst',
      target: '/list',
      group: MenuGroup.Recipe,
      level: AuthLevel.Cook,
    },
  ]);
});

onUnmounted(() => {
  store.commit(Mutations.RemoveMenuGroup, MenuGroup.Recipe);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageTitle),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        (type.value === 'chef')
          ? (_openBlock(), _createBlock(LoginChef, { key: 0 }))
          : _createCommentVNode("", true),
        (type.value === 'admin')
          ? (_openBlock(), _createBlock(LoginAdmin, { key: 1 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("section", _hoisted_3, [
        _createVNode(PageMenu)
      ])
    ])
  ], 64))
}
}

})