import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item" }

import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { ModalMutations } from '../common/modal/modal.store';
import { Ingredient, NEW_ITEM_ID, baseIngredient } from '@/models/menu.model';
import NameInput from './form/NameInput.vue';
import { MenuActions } from './menu.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'IngredientForm',
  setup(__props) {

const store = useStore();

const editIngredient = computed<Ingredient>(
  () => store.state.us.editIngredient,
);
const ingredient = reactive<Ingredient>({
  ...baseIngredient,
  ...editIngredient.value,
});

const action = computed<string>(() =>
  ingredient.id === NEW_ITEM_ID ? 'Add' : 'Edit',
);

watch(
  () => editIngredient,
  (newValue) => {
    Object.assign(ingredient, { ...baseIngredient, ...newValue.value });
  },
  { deep: true },
);

const cancel = () => {
  store.commit(ModalMutations.CloseModal);
};

const submit = async () => {
  if (ingredient.id !== NEW_ITEM_ID) {
    await store.dispatch(MenuActions.UpdateIngredient, ingredient);
  } else {
    await store.dispatch(MenuActions.CreateIngredient, ingredient);
  }
  cancel();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "box box--tertiary box-modal",
    onSubmit: _withModifiers(submit, ["prevent"])
  }, [
    _createElementVNode("h2", null, _toDisplayString(action.value) + " an Ingredient", 1),
    _createVNode(NameInput, {
      modelValue: ingredient,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ingredient) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("label", null, "Type", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ingredient.type) = $event))
      }, null, 512), [
        [_vModelText, ingredient.type]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", null, "Notes", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ingredient.notes) = $event))
      }, null, 512), [
        [_vModelText, ingredient.notes]
      ])
    ]),
    _createElementVNode("div", { class: "form-buttons" }, [
      _cache[5] || (_cache[5] = _createElementVNode("label", null, null, -1)),
      _createElementVNode("button", {
        type: "button",
        onClick: cancel
      }, "Cancel"),
      _cache[6] || (_cache[6] = _createElementVNode("button", { type: "submit" }, "Save", -1))
    ])
  ], 32))
}
}

})