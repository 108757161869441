import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "box box--secondary" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["onClick"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { AuthLevel } from '../../models/auth.model';
import { User } from '@/models/user.model';
import { date } from '@/components/common/filters/date.filter';
import { Mutations } from '@/models/store.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'Users',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();

const users = computed(() => store.state.users);

const userType = (level: AuthLevel) => {
  switch (level) {
    case AuthLevel.Chef:
      return 'Chef';
    case AuthLevel.Admin:
      return 'Administrator';
    case AuthLevel.Cook:
    default:
      return 'Kok';
  }
};
const choose = (user: User) => {
  if (route.params.action) {
    router.push('/admin');
  }
  store.commit(Mutations.SetEditUser, user);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", null, "Gebruikers", -1)),
    _createElementVNode("ul", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("li", { class: "item header" }, [
        _createElementVNode("div", null, "Naam"),
        _createElementVNode("div", null, "Laatste inlog datum"),
        _createElementVNode("div", null, "Type")
      ], -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user) => {
        return (_openBlock(), _createElementBlock("li", {
          key: user.id,
          class: "item"
        }, [
          _createElementVNode("a", {
            class: "user",
            onClick: _withModifiers(($event: any) => (choose(user)), ["prevent"])
          }, [
            _createElementVNode("div", null, _toDisplayString(user.name), 1),
            _createElementVNode("div", null, _toDisplayString(_unref(date)(user.last_login_date)), 1),
            _createElementVNode("div", null, _toDisplayString(userType(user.user_level)), 1)
          ], 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}
}

})