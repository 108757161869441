import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ingredients" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

import { Recipe } from '@/models/recipe.model';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeIngredients',
  props: {
  recipe: {
    type: Recipe,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Ingrediënten", -1)),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.recipe.ingredients, (ingredient, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "ingredient",
          key: index
        }, [
          (ingredient.amount)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "amount",
                textContent: _toDisplayString(ingredient.amount)
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: "name",
            textContent: _toDisplayString(ingredient.name)
          }, null, 8, _hoisted_3),
          (ingredient.remark)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "remark",
                textContent: _toDisplayString(ingredient.remark)
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}
}

})