import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch" }

import { MomsState, Mutations } from '@/models/store.model';
import { onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'CookingMode',
  setup(__props) {

const store = useStore<MomsState>();
const cookingMode = ref<boolean>(false);

const toggle = () => {
  cookingMode.value = !cookingMode.value;
  store.commit(Mutations.ToggleNoSleep, cookingMode.value);
};

onUnmounted(() => {
  store.commit(Mutations.ToggleNoSleep, false);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      name: "cookingmode",
      type: "checkbox",
      onChange: toggle
    }, null, 32),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "slider round" }, null, -1))
  ]))
}
}

})