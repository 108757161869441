import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onKeydown"]
const _hoisted_2 = {
  key: 0,
  class: "list"
}
const _hoisted_3 = ["value", "textContent", "onClick"]

import { computed, onMounted, PropType, ref, watch } from 'vue';

export interface Item {
  label: string;
  search?: string;
  value: string | any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Autocomplete',
  props: {
  modelValue: {
    required: true,
  },
  items: {
    type: Array as PropType<Item[]>,
    required: true,
  },
  focus: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue', 'changed'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// the selected item, used to show label on blur
const item = ref<any>(
  props.items.find((v) => v.value === props.modelValue) || null,
);
// the displayed search value
const search = ref<string>(
  props.items.find((v) => v.value === props.modelValue)?.label || '',
);

const input = ref<any>(null);
const open = ref<boolean>(false);
const focusIndex = ref<number>(0);

watch(
  props,
  () => {
    item.value = props.items.find((v) => v.value === props.modelValue) || null;
    search.value =
      props.items.find((v) => v.value === props.modelValue)?.label || '';
  },
  { deep: true },
);

const itemsFiltered = computed<Item[]>(() => {
  return props.items.filter((v) =>
    `${v.label} ${v.search} ${v.value}`
      .toLowerCase()
      .includes(search.value.toLowerCase()),
  );
});

const openClass = computed<string>(() =>
  open.value && search.value && itemsFiltered.value.length
    ? 'field-auto-complete-open'
    : '',
);

const setItem = () => {
  item.value = props.items.find((i) => i.value === props.modelValue);
};

const update = () => {
  emit('update:modelValue', item.value.value);
};

const toggleList = () => {
  open.value = !open.value;
  focusIndex.value =
    open.value && item.value
      ? itemsFiltered.value.findIndex((i) => i.value === item.value.value)
      : -1;
};

const close = () => {
  open.value = false;
};
const clear = () => {
  item.value = null;
  emit('update:modelValue', '');
};

const blur = () => {
  // prevent the blur from closing before a choose function has had time to work
  setTimeout(() => close(), 250);
};

const choose = () => {
  if (open.value) {
    chooseItem(focusIndex.value);
  }
};

const chooseItem = (index: number) => {
  const i = itemsFiltered.value[index];
  item.value = i;
  search.value = i.label;

  emit('changed', i);
  update();
  close();
};

const focusClass = (index: number) => {
  return index === focusIndex.value ? 'choice-focus' : '';
};

const next = () => {
  if (!open.value) {
    toggleList();
    return;
  }
  focusIndex.value++;
  if (focusIndex.value > itemsFiltered.value.length - 1) {
    focusIndex.value = 0;
  }
};

const previous = () => {
  focusIndex.value--;
  if (focusIndex.value < 0) {
    focusIndex.value = itemsFiltered.value.length - 1;
  }
};

onMounted(() => {
  setItem();
  if (props.focus) {
    input.value.focus();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('field-auto-complete ' + openClass.value)
  }, [
    _withDirectives(_createElementVNode("input", {
      class: "visible",
      type: "text",
      ref_key: "input",
      ref: input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (open.value = true)),
      onBlur: blur,
      onKeydown: [
        _withKeys(clear, ["backspace"]),
        _withKeys(_withModifiers(close, ["prevent"]), ["esc"]),
        _withKeys(_withModifiers(choose, ["prevent"]), ["enter"]),
        _withKeys(_withModifiers(previous, ["prevent"]), ["up"]),
        _withKeys(_withModifiers(next, ["prevent"]), ["down"])
      ]
    }, null, 40, _hoisted_1), [
      [_vModelText, search.value]
    ]),
    (open.value && itemsFiltered.value.length && search.value.length > 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemsFiltered.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(focusClass(index)),
              value: item.value,
              textContent: _toDisplayString(item.label),
              key: item.label,
              onClick: _withModifiers(($event: any) => (chooseItem(index)), ["stop","prevent"])
            }, null, 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})